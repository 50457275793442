import { Image } from 'antd';
import React from 'react';
import { Autoplay, EffectCoverflow, Keyboard, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/free-mode";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Cards from './Cards';

const CarrouselFirstDetail = (props) => {
  return (
    <div className='description-proyects'>
      <div className="banner_container_detail_swipper">
        <div className='container-swipper_one'>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          navigation={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 60,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false
          }}
          keyboard={{
            enabled: true
          }}
          pagination={{
            clickable: true
          }}
          modules={[Keyboard, EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          {props.data.map((media) => (
            <>
              <SwiperSlide>
                <Image
                  className="img-modal"
                  src={media.img}
                />
                <div className="text-carousel-detail">
                  <p>{media.name}</p>
                </div>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
        </div>
        <div className='container-swipper_two'>
        <div className="cardtext-detalle-h">
          <Cards>
            <div className="card-body">
              <h2>{props.infoProyect.titulo}</h2>
              <p className="card-text">{props.infoProyect.descripcionCompleta}</p>
              <br /><small className="text-muted">{props.infoProyect.ubicacion}</small>
            </div>
          </Cards>
        </div>
        </div>
      </div>
    </div>
  )
}

export default CarrouselFirstDetail