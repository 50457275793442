import React from 'react'
import fotoPerfil from '../assets/perfil.jpg'

const Perfil = () => {
    return (
        <div className='perfil-usuario'>
            <div className='contenedor'>
                <h3 style={{color:"#878787"}}>Direccionamiento Estrategico</h3>
                <p style={{color:"#978797"}}>DJM construcciones es una empresa que ofrece los servicios de constructora con un alto concepto de calidad y creatividad y se rige bajo principios de Responsabilidad Social Empresarial.</p>
                <h3 style={{color:"#878787"}}>Quienes somos?</h3>
                <p style={{color:"#978797"}}>DJM construcciones es una empresa fundamentada en el bienestar que genera la inversión en bienes raíces, generando un concepto diferente en diseño y calidad en sus obras.</p>
                <h3 style={{color:"#878787"}}>Misión</h3>
                <p style={{color:"#978797"}}>DJM construcciones es una empresa dedicada al diseño, desarrollo y ejecución de proyectos inmobiliarios. Nuestra premisa es satisfacer y generar bienestar a nuestros clientes, empleados y accionistas.</p>
                <h3 style={{color:"#878787"}}>Visión</h3>
                <p style={{color:"#978797"}}>Ser una de las empresas líderes en diseño y construcción del sector inmobiliario, reconocida por su capacidad, calidad y seriedad; desarrollando espacios para una mejor calidad de vida.</p>
                <h3 style={{color:"#878787"}}>Valores</h3>
                <p style={{color:"#978797"}}>Respeto, Responsabilidad, Determinación, Honestidad, Justicia.</p>
                <h3 style={{color:"#878787"}}>Política de Calidad</h3>
                <p style={{color:"#978797"}}>Controlar y satisfacer las necesidades de nuestros clientes. Asegurar la confiabilidad y la imagen de DJM construcciones garantizando el cumplimiento de nuestros compromisos de rentabilidad y responsabilidad social.
                Reconocer el talento humano como el activo indispensable en la proyección de DJM construcciones.
                <br></br>
                <br></br>
                <h3 style={{color:"#878787"}}>Reseña Historica</h3>
                DJM Construcciones S.A.S., se constituye el 5 de junio de 2009, en la ciudad de Manizales, con la razón social ZEPOL Servicios Integrales de Ingeniería S.A.S., 
                siendo inscrita en la Cámara de Comercio de esta ciudad el 10 de junio de 2009; posteriormente el 14 de mayo de 2014, se inscribe en la Cámara de Comercio de la ciudad de Pereira y mediante radicación de Acta de Asamblea de Accionistas del 21 de marzo de 2014, 
                la sociedad legaliza el cambio de razón social a DJM Construcciones S.A.S. y de igual manera cambia su domicilio de la ciudad de Manizales a la ciudad de Pereira.  DJM Construcciones S.A.S., cuenta con el personal calificado y convenios con profesionales en áreas como la Ingeniería, Arquitectura y Financiera, 
                permitiendo ofrecer un Servicio Integral en Proyectos.</p>
                <h6 style={{color:"#878787"}}>La experiencia de la empresa y del personal, permiten ofrecer los siguientes servicios:</h6>
                <p style={{color:"#978797"}}> - Proyectos Obras Civiles</p>
                <p style={{color:"#978797"}}> - Gestión Inmobiliaria </p>
                <p style={{color:"#978797"}}> - Consultoría Financiera </p>
                {/* <div className='card-perfil'>
                    <div className="card" style={{ width: "18rem", marginRight: "25px" }}>
                        <div className='img-perfil'>
                            <img src={fotoPerfil} className="card-img-top" alt="..." style={{ height: "150px", width: "150px", borderRadius: "150px", border: "2px solid #666" }} />
                        </div>

                        <div className="card-body">
                            <h5 className="card-title" style={{textAlign:"center"}}>John Albeiro Gonzalez</h5>
                            <p className="card-text" style={{textAlign:"center"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates officiis molestiae minus facilis odit deserunt, molestias autem ipsa omnis consequatur dicta? Iusto excepturi, earum architecto officiis quibusdam voluptatem vero aperiam?</p>
                        </div>
                        <div className="card-body" style={{textAlign:"center"}}>
                            <a href="#" className="card-link">Card link</a>
                            <a href="#" className="card-link">Another link</a>
                        </div>
                    </div>

                    <div className="card" style={{ width: "18rem", marginRight: "25px" }}>
                        <div className='img-perfil'>
                            <img src={fotoPerfil} className="card-img-top" alt="..." style={{ height: "150px", width: "150px", borderRadius: "150px", border: "2px solid #666" }} />
                        </div>

                        <div className="card-body">
                            <h5 className="card-title" style={{textAlign:"center"}}>Card title</h5>
                            <p className="card-text" style={{textAlign:"center"}}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                        <div className="card-body" style={{textAlign:"center"}}>
                            <a href="#" className="card-link">Card link</a>
                            <a href="#" className="card-link">Another link</a>
                        </div>
                    </div>

                    <div className="card" style={{ width: "18rem", marginRight: "25px" }}>
                        <div className='img-perfil'>
                            <img src={fotoPerfil} className="card-img-top" alt="..." style={{ height: "150px", width: "150px", borderRadius: "150px", border: "2px solid #666" }} />
                        </div>

                        <div className="card-body">
                            <h5 className="card-title" style={{textAlign:"center"}}>Card title</h5>
                            <p className="card-text" style={{textAlign:"center"}}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                        <div className="card-body" style={{textAlign:"center"}}>
                            <a href="#" className="card-link">Card link</a>
                            <a href="#" className="card-link">Another link</a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Perfil
