import { React, useState, useEffect, useCallback } from 'react'
import './style/theme.scss'
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom'
import { auth, firebase } from './Firebase'

import Footer from "./components/Footer"
import Perfil from './components/Perfil'
import Contacts from './components/Contacts'
import Proyects from './components/Proyects'
import Swal from 'sweetalert2'

//naterial ui
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import banner from '../src/assets/banner.png'
import { TextField, Box, Modal, Button, fabClasses } from '@mui/material'
import { MutatingDots } from 'react-loader-spinner'
import CancelIcon from '@mui/icons-material/Cancel';
import DetailProyect from './subcomponentes/DetailProyect'


function App() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [value, setValue] = useState('recents');
  const [modal, setModal] = useState(false)
  const [error, setError] = useState("")
  const [activeSpiner, setActiveSpiner] = useState(false)
  const [usuario, setUsuario] = useState("")
  const [pass, setPass] = useState("")
  const [admin, setAdmin] = useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const validarDatos = (e) => {
    e.preventDefault()
    if (!usuario.trim()) {
      setError("debe ingresar el email")
      return
    }
    if (!pass.trim()) {
      setError("debe ingresar una contraseña")
      return
    }
    setError(null)
    login()
  }

  const login = useCallback(async () => {
    console.log("ok")
    try {
      const res = await auth.signInWithEmailAndPassword(usuario, pass)
      setModal(false)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Bienvenido Administrador',
        showConfirmButton: false,
        timer: 2000
      })
      setAdmin(true)
      localStorage.setItem("active",true)
    } catch (error) {
      setAdmin(false)
      if (error.code === 'auth/invalid-email') {
        setError("El correo electronico introducido no es valido");
      }
      if (error.code === "auth/user-not-found") {
        setError("Usuario o Contraseña no validos");
      }
      if (error.code === "auth/wrong-password") {
        setError("Usuario o Contraseña no validos");
      }
    }
  }, [usuario, pass])

  const prueba = (e) => {
    console.log(e.target.value);
  }

  useEffect(() => {
    let URLactual = (new URL(document.location)).searchParams;
    let valor = URLactual.get("admin") //valor de la compra
    if (valor == "admin") {
      setModal(true)
    } else {
      setModal(false)
    }

  }, [])

  return (
    <div>
      <Router>
        <div className='body'>
          <nav className='navbar' style={{ backgroundImage: `url(${banner})` }}>
            <div className='icons-navbar'>
                <Link to="/">
                  <BottomNavigation showLabels className="size-icons" value={value} onChange={handleChange} style={{ background: "none" }}>
                    <BottomNavigationAction
                      label="Proyectos"
                      value="favorites"
                      style={{ color: "#3487B9" }}
                      icon={<MapsHomeWorkIcon className="format-icons"  />}
                    />
                  </BottomNavigation>
                </Link>
                <Link to="/contacto">
                  <BottomNavigation showLabels className="size-icons" value={value} onChange={handleChange} style={{ background: "none" }}>
                    <BottomNavigationAction
                      label="Contactenos"
                      value="folder"
                      style={{ color: "#3487B9" }}
                      icon={<MarkEmailReadIcon  className="format-icons"/>}
                    />
                  </BottomNavigation>
                </Link>
                <Link to="/nosotros">
                  <BottomNavigation showLabels className="size-icons" value={value} onChange={handleChange} style={{ background: "none" }}>
                    <BottomNavigationAction
                      label="Quienes somos? "
                      value="nearby"
                      style={{ color: "#3487B9" }}
                      icon={<PeopleAltIcon  className="format-icons"/>}
                    />
                  </BottomNavigation>
                </Link>
               
                
              </div>
            
          </nav>
        </div>
        <Switch>
          <Route exact path="/contacto"> <Contacts /> </Route>
          <Route exact path="/nosotros"> <Perfil /> </Route>
          <Route exact path="/detalle"><DetailProyect /></Route>
          <Route exact path="/"> <Proyects login={admin} detalle={false}/> </Route>
        </Switch>
        {/* <Footer /> */}
      </Router>
      <div>
      </div>
      <Modal
        hideBackdrop
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modal-loguin"
      >
        {activeSpiner == true ? (
          <Box sx={{ ...style, width: 400 }}>
            <div className='alert'>
              <div className="spinner-2">
                <MutatingDots
                  heigth="100"
                  width="100"
                  color='grey'
                  ariaLabel='loading'
                  visible={activeSpiner}
                />
              </div>
            </div>
          </Box>
        ) : (
          <Box sx={{ ...style, width: 400 }}>
            <div className='titulo'>
              <h4 id="child-modal-title">Loguin</h4>
              <div className="cierra-boton">
                <CancelIcon onClick={() => setModal(false)} style={{ float: "right" }} />
              </div>
            </div>
            <form onSubmit={validarDatos}>
              {error && (
                <div className="alert-danger" style={{ marginBotton: "10px" }}>{error}</div>
              )}
              <br />
              <input
                type="text"
                className='form-control'
                placeholder="Usuario"
                onChange={e => setUsuario(e.target.value)}

              />
              <br />
              <input
                type="password"
                className='form-control'
                placeholder="Contraseña"
                onChange={e => setPass(e.target.value)}

              />
              <br />
              <div className="boton-loguin">
                <Button variant="contained" disableElevation type='submit'>LOGUIN</Button>
              </div>
            </form>
          </Box>
        )}
      </Modal>
    </div>
  );
}

export default App;
