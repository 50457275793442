import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, Button, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { React, useEffect, useState } from 'react';
import { BsFillFilePdfFill, BsFolderCheck } from "react-icons/bs";
import { FiUploadCloud, FiUserCheck } from "react-icons/fi";
import { MutatingDots } from 'react-loader-spinner';
import { MultiSelect } from "react-multi-select-component";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { db, storage } from '../Firebase';
import FirstCarrousel from '../subcomponentes/FirstCarrousel.jsx';


const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const Proyects = (props) => {
    let history = useHistory();
    const [admin, setAdmin] = useState(false)
    const [index, setIndex] = useState(0);
    const [activeForm, setActiveForm] = useState(true)
    const [proyectos, setProyectos] = useState([])
    const [proyectosVendidos, setProyectosVendidos] = useState([])
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modal3, setModal3] = useState(false)
    const [error, setError] = useState("")
    const [activeSpiner, setActiveSpiner] = useState(false)
    const [activeSpiner2, setActiveSpiner2] = useState(false)
    const [loadingImgDetail, setLoadingImgDetail] = useState(false)
    //componentes para la creacion o edicion de un nuevo proyecto 
    const [addTitulo, setAddTitulo] = useState("")
    const [addDescripcion, setAddDescripcion] = useState("")
    const [addDescripcion1, setAddDescripcion1] = useState("")
    const [addDescripcionCompleta, setAddDescripcionCompleta] = useState("")
    const [addUbicacion, setAddUbicacion] = useState("")
    const [addImagen, setAddImagen] = useState()
    const [editImagen, setEditImagen] = useState()
    const [imgUpload, setImgUpload] = useState(false)
    const [addpdf, setAddPdf] = useState()
    const [addpdf1, setAddPdf1] = useState()
    const [addpdf2, setAddPdf2] = useState()
    const [addImagenDetalle, setAddImagenDetalle] = useState()
    const [addImagenDiseños, setAddImagenDiseños] = useState()
    const [addImagenDetail, setAddImagenDetail] = useState()
    const [addLocalizacion, setAddLocalizacion] = useState("")
    const [editProjects, setEditProjects] = useState(false)
    const [idDocumentoId, setIdDocumentoId] = useState("")
    const [id, setId] = useState("")
    const [ItemSelected, setItemSelected] = useState([]);
    //componentes para un proyecto seleccionado
    const [imgProyectSelect, setImgProyectSelect] = useState([])
    const [imgProyectDiseñosSelect, setImgProyectDiseñosSelect] = useState([])
    const [pdfsProyectSelect, setPdfsProyectSelect] = useState([])
    const options = [
        { label: "Accesibilidad / Apto para personas con movilidad reducida", value: "1" },
        { label: "Área de BBQ", value: "2" },
        { label: "Ascensor", value: "3" },
        { label: "Baños", value: "4" },
        { label: "Baño turco", value: "5" },
        { label: "Balcón", value: "6" },
        { label: "Buena vista / Paisaje", value: "7" },
        { label: "Cancha múltiple / Espacios deportivos", value: "8" },
        { label: "Circuito cerrado de TV", value: "9" },
        { label: "Coworking / Salón de trabajo", value: "10" },
        { label: "Estudio", value: "11" },
        { label: "Gimnasio", value: "12" },
        { label: "Habitaciones", value: "13" },
        { label: "Jacuzzi", value: "14" },
        { label: "Juegos infantiles", value: "15" },
        { label: "Mascota / Petfriendly", value: "16" },
        { label: "Multifamiliar", value: "17" },
        { label: "Naturaleza / Campestre", value: "18" },
        { label: "Parqueaderos", value: "19" },
        { label: "Piscina", value: "20" },
        { label: "Planta eléctrica", value: "21" },
        { label: "Portería", value: "22" },
        { label: "Sauna", value: "23" },
        { label: "Senderos", value: "24" },
        { label: "Shut de basuras", value: "25" },
        { label: "Solarium", value: "26" },
        { label: "Terraza recreativa / Zona de picnic", value: "27" },
        { label: "Unifamiliar", value: "28" },
        { label: "Vigilancia y seguridad", value: "29" },
        { label: "VIS", value: "30" },
        { label: "NO VIS", value: "31" },
        { label: "Wifi", value: "32" },
        { label: "Zonas verdes/Jardín", value: "33" },
        { label: "Zona social", value: "34" },
    ];
    const [iCgGym, setICgGym] = useState(false)
    const [iMdOutlineSmartToy, setIMdOutlineSmartToy] = useState(false)
    const [iGrUserPolice, setIGrUserPolice] = useState(false)
    const [iGiFloorPolisher, setIGiFloorPolisher] = useState(false)
    const [iFaSwimmingPool, setIFaSwimmingPool] = useState(false)
    const [iGrSolaris, setIGrSolaris] = useState(false)
    const [ijacuzzi, setIjacuzzi] = useState(false)
    const [isauna, setIsauna] = useState(false)
    const [ibañoTurco, setIbañoTurco] = useState(false)
    const [izonaSocial, setIzonaSocial] = useState(false)
    const [ibbq, setIbbq] = useState(false)
    const [iGiFlowerPot, setIGiFlowerPot] = useState(false)
    const [iGiElevator, setIGiElevator] = useState(false)
    const [iFaWheelchair, setIFaWheelchair] = useState(false)
    const [iGiSoccerKick, setIGiSoccerKick] = useState(false)
    const [iGrWorkshop, setIGrWorkshop] = useState(false)
    const [iBiLandscape, setIBiLandscape] = useState(false)
    const [iMdBalcony, setIMdBalcony] = useState(false)
    const [iMdPeopleAlt, setIMdPeopleAlt] = useState(false)
    const [iMdEmojiPeople, setIMdEmojiPeople] = useState(false)
    const [iGiStonePath, setIGiStonePath] = useState(false)
    const [iMdPets, setIMdPets] = useState(false)
    const [iGiElectricalResistance, setIGiElectricalResistance] = useState(false)
    const [iFaTrashRestore, setIFaTrashRestore] = useState(false)
    const [iSiPicnic, setISiPicnic] = useState(false)
    const [iGiForestCamp, setIGiForestCamp] = useState(false)
    const [iHiOutlineCash, setIHiOutlineCash] = useState(false)
    const [iHiOutlineCashnoVis, setIHiOutlineCashnoVis] = useState(false)
    const [iFaWifi, setIFaWifi] = useState(false)
    const [iGiCctvCamera, setIGiCctvCamera] = useState(false)
    const [iMdBathroom, setIMdBathroom] = useState(false)
    const [iMdMeetingRoom, setIMdMeetingRoom] = useState(false)
    const [iMdComputer, setIMdComputer] = useState(false)
    const [iFaCarSide, setIFaCarSide] = useState(false)

    //se obtienen los datos de un proyecto en especifico para ser mostrado en el detalle
    const seleccionproyect = (item) => {
        let idItem = item.data.id.toString()
        let idProyecto = item.id
        history.push('detalle/?id=' + idProyecto + '&doc=' + idItem)
    }
    //limpia los items seleccionados para mantener actualizados
    const cleanItems = () => {
        setICgGym(false)
        setIMdOutlineSmartToy(false)
        setIGrUserPolice(false)
        setIGiFloorPolisher(false)
        setIFaSwimmingPool(false)
        setIGrSolaris(false)
        setIjacuzzi(false)
        setIsauna(false)
        setIbañoTurco(false)
        setIzonaSocial(false)
        setIbbq(false)
        setIGiFlowerPot(false)
        setIGiElevator(false)
        setIFaWheelchair(false)
        setIGiSoccerKick(false)
        setIGrWorkshop(false)
        setIBiLandscape(false)
        setIMdBalcony(false)
        setIMdPeopleAlt(false)
        setIMdEmojiPeople(false)
        setIGiStonePath(false)
        setIMdPets(false)
        setIGiElectricalResistance(false)
        setIFaTrashRestore(false)
        setISiPicnic(false)
        setIGiForestCamp(false)
        setIHiOutlineCash(false)
        setIFaWifi(false)
        setIGiCctvCamera(false)
        setIMdBathroom(false)
        setIMdMeetingRoom(false)
        setIMdComputer(false)
        setIFaCarSide(false)
    }
    const getNewProjects = async () => {
        setAddPdf1(null)
        setAddPdf2(null)
        setAddPdf(null)
        setActiveForm(false)
        let docRef = []
        await db.collection("proyectos_realizados")
        .where("vendido", "==", 0)
        .where("state", "==" , 1)
        .orderBy("fechaCreacion", "desc").get().then((query) => {
            query.forEach((doc) => {
                docRef.push({
                    id: doc.id,
                    data: doc.data()
                })
            })
        })
        setProyectos(docRef)
        console.log(proyectos);
    }

    const getProjectsVendidos = async () => {
        let docRef = []
        await db.collection("proyectos_realizados").where("vendido", "==", 1)
        .where("state", "==" , 1)
        .orderBy("fechaCreacion", "desc").get().then((query) => {
            query.forEach((doc) => {
                docRef.push({
                    id: doc.id,
                    data: doc.data()
                })
            })
        })
        setProyectosVendidos(docRef)
    }

    const prepareNewProjects = () => {
        setAddTitulo("")
        setAddDescripcion("")
        setAddUbicacion("")
        setAddDescripcionCompleta("")
        setAddLocalizacion("")
        setItemSelected([])
        setEditProjects(false)
        setActiveForm(true)
    }

    const newProyects = async (e) => {
        e.preventDefault()
        var pdf = null
        var pdf1 = null
        var pdf2 = null
        if (addpdf != null) {
            pdf = addpdf.target.files[0]
        } else {
            pdf = null
        }
        if (addpdf1 != null) {
            pdf1 = addpdf1.target.files[0]
        } else {
            pdf1 = null
        }
        if (addpdf2 != null) {
            pdf2 = addpdf2.target.files[0]
        } else {
            pdf2 = null
        }

        if (!addTitulo.trim()) {
            setError("El nombre no debe estar vacio");
            return
        }
        if (!addUbicacion.trim()) {
            setError("La dirección no debe estar vacia");
            return
        }
        if (!addLocalizacion.trim()) {
            setError("La latitud y longitud no debe estar vacia");
            return
        }
        if (ItemSelected.length == 0) {
            setError("Debe seleccionar al menos un Item de la propiedad")
            return
        }
        if (!addDescripcion.trim()) {
            setError("La descripcion no debe estar vacia");
            return
        }
        if (!addDescripcionCompleta.trim()) {
            setError("La descripcion completa no debe estar vacia");
            return
        }
        if (addImagen == undefined) {
            setError("La imagen principal del proyecto es obligatoria");
            return
        }
        if (addImagenDetalle == undefined) {
            setError("La imagen para el detalle del proyecto es obligatoria");
            return
        }
        if(addImagenDiseños.target.files.length == 0 || addImagenDiseños == undefined){
            setError("La imagen para el detalle del proyecto es obligatoria");
            return
        }
        setActiveSpiner(true)
        let geolocalizacion = addLocalizacion.split(",")
        let latitud = parseFloat(geolocalizacion[0])
        let longitud = parseFloat(geolocalizacion[1])

        var finalId = 0
        await db.collection("proyectos_realizados").orderBy("id", "desc").limit(1)
            .get().then((result) => {
                result.docs.forEach(doc => {
                    let datos = doc.data()
                    finalId = datos.id
                })
            })

        const img = addImagen.target.files[0]
        const imgDetalle = addImagenDetalle.target.files
        const imgDiseños = addImagenDiseños.target.files
        setTimeout(async () => {
            setActiveSpiner(true)
            try {
                let prevId = finalId + 1
                let data = prevId.toString()
                const imagenRef = await storage.ref().child(data).child("imgProyecto").child("principal")
                await imagenRef.put(img)
                const imagenURL = await imagenRef.getDownloadURL()

                var pdfurl = ''
                var namePdf = ''
                if (pdf !== null) {
                    const pdfref = await storage.ref().child(data).child("document").child(pdf.name)
                    await pdfref.put(pdf)
                    pdfurl = await pdfref.getDownloadURL()
                    namePdf = pdf.name
                } else {
                    pdfurl = 'no'
                    namePdf = 'no'
                }

                var pdfurl1 = ''
                var namePdf1 = ''
                if (pdf1 !== null) {
                    const pdfref = await storage.ref().child(data).child("document").child(pdf1.name)
                    await pdfref.put(pdf1)
                    pdfurl1 = await pdfref.getDownloadURL()
                    namePdf1 = pdf1.name
                } else {
                    pdfurl1 = 'no'
                    namePdf1 = 'no'
                }

                var pdfurl2 = ''
                var namePdf2 = ''
                if (pdf2 !== null) {
                    const pdfref = await storage.ref().child(data).child("document").child(pdf2.name)
                    await pdfref.put(pdf2)
                    pdfurl2 = await pdfref.getDownloadURL()
                    namePdf2 = pdf2.name
                } else {
                    pdfurl2 = 'no'
                    namePdf2 = 'no'
                }

                for (const index of imgDetalle) {
                    console.log(imgDetalle);
                    const ImagenRefDetalle = await storage.ref().child(data).child("imgProyectoDetalle").child(index.name)
                    await ImagenRefDetalle.put(index)
                }

                for (const index of imgDiseños) {
                    const ImagenRefDiseños = await storage.ref().child(data).child("imgProyectoDiseños").child(index.name)
                    await ImagenRefDiseños.put(index)
                }
                await db.collection("proyectos_realizados").add({
                    id: finalId + 1,
                    descripcion: addDescripcion,
                    descripcion1 : addDescripcion1.length > 0 ? addDescripcion1 : "false",
                    titulo: addTitulo,
                    ubicacion: addUbicacion,
                    urlImagen: imagenURL,
                    vendido: 0,
                    latitud: latitud,
                    longitud: longitud,
                    items: ItemSelected,
                    descripcionCompleta: addDescripcionCompleta,
                    pdfUrl1: pdfurl,
                    pdfName1 : namePdf,
                    pdfUrl2: pdfurl1,
                    pdfName2 : namePdf1,
                    pdfUrl3: pdfurl2,
                    pdfName3 : namePdf2, 
                    fechaCreacion : new Date(),
                    state : 1
                })
                setTimeout(() => {
                    setModal(false)
                    setActiveSpiner(false)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Se ha registrado el proyecto',
                        showConfirmButton: false,
                        timer: 2500
                    })
                    getNewProjects()
                    setActiveForm(false)
                }, 2000);

            } catch (error) {
                setModal(false)
                setActiveSpiner(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrio un error al registrar el proyecto por favor intenta de nuevo!',
                })
            }

        }, 500);
    }

    const deleteProyects = (item) => {
      let data = {
        state : 0
    }
        try {
          let refSalas = db.collection("proyectos_realizados").doc(item.id)
          refSalas.update(data)
          Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Se ha actualizado el proyecto',
              showConfirmButton: false,
              timer: 2500
          })
          getNewProjects()
          getProjectsVendidos()

      } catch (error) {
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al eliminar el proyecto por favor intenta de nuevo!',
          })
          getNewProjects()
          getProjectsVendidos()
      }
    }

    //preparamos los datos para editar el proyecto
    const prepareEditProject = (item) => {
        setEditProjects(true)
        setActiveForm(true)
        setAddTitulo(item.data.titulo)
        setAddDescripcion(item.data.descripcion)
        setAddDescripcion1(item.data.descripcion1 == "false" ? "" :item.data.descripcion1 )
        setAddUbicacion(item.data.ubicacion)
        setIdDocumentoId(item.id)
        setId(item.data.id)
        setAddDescripcionCompleta(item.data.descripcionCompleta)
        setAddLocalizacion(`${item.data.latitud}, ${item.data.longitud}`)
        setItemSelected(item.data.items)
        setEditImagen(item.data.urlImagen)
        let imagenes = []
        let idItem = item.data.id.toString()
        storage.ref(`${idItem}/imgProyectoDetalle`).listAll()
            .then(async response => {
                for (const iterator of response["items"]) {
                    const url = await iterator.getDownloadURL();
                    const nombre = iterator.name
                    imagenes.push({ img: url, name: nombre })
                }
            }).catch(error => {
                console.log(error);
            })
        setImgProyectSelect(imagenes)
        let diseños = []
        let idItems = item.data.id.toString()
        storage.ref(`${idItems}/imgProyectoDiseños`).listAll()
            .then(async response => {
                for (const iterator of response["items"]) {
                    const url = await iterator.getDownloadURL();
                    const nombre = iterator.name
                    diseños.push({ img: url, name: nombre })
                }
            }).catch(error => {
                console.log(error);
            })
        setImgProyectDiseñosSelect(diseños)
        let pdfs = []
        let orderPdf = 0
        storage.ref(`${idItem}/document`).listAll()
            .then(async response => {
                for (const iterator of response["items"]) {
                    const url = await iterator.getDownloadURL();
                    const nombre = iterator.name
                    pdfs.push({ img: url, name: nombre, id:orderPdf ++})
                }
            }).catch(error => {
                console.log(error);
            })
        setPdfsProyectSelect(pdfs)
        console.log(pdfs);
    }

    const updateProyect = (e) => {
        e.preventDefault()
        let geolocalizacion = addLocalizacion.split(",")
        let newLatitud = parseFloat(geolocalizacion[0])
        let newLongitud = parseFloat(geolocalizacion[1])
        let data = {
            id: id,
            descripcion: addDescripcion,
            descripcion1: addDescripcion1.length > 0 ? addDescripcion1 : "false",
            titulo: addTitulo,
            ubicacion: addUbicacion,
            latitud: newLatitud,
            longitud: newLongitud,
            items: ItemSelected,
            descripcionCompleta: addDescripcionCompleta
        }
        try {
            let refSalas = db.collection("proyectos_realizados").doc(idDocumentoId)
            refSalas.update(data)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Se ha actualizado el proyecto',
                showConfirmButton: false,
                timer: 2500
            })
            setTimeout(() => {
                getNewProjects()
                setModal(false)
            }, 2000);

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al actualizar el proyecto por favor intenta de nuevo!',
            })
            setModal(false)
            getNewProjects()
        }
    }

    const updateImagen = async (e) => {
        e.preventDefault()
        let idUser = id.toString()
        setImgUpload(true)
        setActiveSpiner2(true)
        const img = e.target.files[0]
        const imagenRef = await storage.ref().child(idUser).child("imgProyecto").child("principal")
        await imagenRef.put(img)
        const imagenURL = await imagenRef.getDownloadURL()
        let refSala = db.collection("proyectos_realizados").doc(idDocumentoId)
        refSala.update({ urlImagen: imagenURL })
        setActiveSpiner2(false)
        setModal(false)
        window.location.reload()
    }

    const updatePdf = async (e, item) => {
        setLoadingImgDetail(true)
        e.preventDefault()
        let idUser = id.toString()
        const pdf = e.target.files[0]
        let desertRef = storage.ref(`${idUser}/document/${item.name}`)
        desertRef.delete().then(async() => {
          const pdfRef = await storage.ref().child(idUser).child('document').child(pdf.name)
          await pdfRef.put(pdf)
          const pdfurl = await pdfRef.getDownloadURL()
          let refSala = db.collection("proyectos_realizados").doc(idDocumentoId)
          if(item.id == 0){
              refSala.update({pdfName1 : pdf.name , pdfUrl1 : pdfurl})
          }else if (item.id == 1){
              refSala.update({pdfName2 : pdf.name , pdfUrl2 : pdfurl})
          }else if(item.id == 2){
              refSala.update({pdfName2 : pdf.name , pdfUrl2 : pdfurl})
          }
          setTimeout(() => {
              setLoadingImgDetail(false)
              setModal2(false)
              setEditProjects(false)
          }, 5000);
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al eliminar imagen, por favor intenta de nuevo!',
            })
        });

       
    }

    const proyectoVendido = async (data) => {
        let newdata = {
            vendido: 1
        }
        Swal.fire({
            title: 'Desea marcar como vendido el proyecto ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    let refSalas = db.collection("proyectos_realizados").doc(data.id)
                    refSalas.update(newdata)
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Se ha actualizado el proyecto',
                        showConfirmButton: false,
                        timer: 2500
                    })
                    getNewProjects()
                    getProjectsVendidos()
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ocurrio un error al actualizar el proyecto por favor intenta de nuevo!',
                    })
                    getNewProjects()
                    getProjectsVendidos()
                }
            }
        })

    }

    const openEditImagen = (tipo) => {
        if (tipo == 1) {
            setModal(true)
        } else if(tipo == 2) {
            setModal1(true)
        }else{
            setModal3(true)
        }
        setAddImagenDetail("")
        setAddImagenDiseños("")
        setLoadingImgDetail(true)
        setTimeout(() => {
            setLoadingImgDetail(false)
        }, 5000);
    }

    const openEditPdfs = () => {
        setModal2(true)
        setLoadingImgDetail(true)
        setTimeout(() => {
            setLoadingImgDetail(false)
        },)
    }

    const añadirPdf = async (e, item) => {
        setLoadingImgDetail(true)
        let idUser = id.toString()
        const pdf = e.target.files[0]
        const pdfRef = await storage.ref().child(idUser).child("document").child(pdf.name)
        await pdfRef.put(pdf)
        const pdfUrl = await pdfRef.getDownloadURL()
        let refSalas = db.collection("proyectos_realizados").doc(idDocumentoId)
        .get().then((res)=>{
            let result = res.data()
            if(result.pdfName1 == "no"){
                let refSalasupdate = db.collection("proyectos_realizados").doc(idDocumentoId)
                refSalasupdate.update({pdfName1 : pdf.name , pdfUrl1 : pdfUrl})

            }else if(result.pdfName2 == "no"){
                let refSalasupdate = db.collection("proyectos_realizados").doc(idDocumentoId)
                refSalasupdate.update({pdfName2 : pdf.name , pdfUrl2 : pdfUrl})
                
            }else if(result.pdfName3 == "no"){
                let refSalasupdate = db.collection("proyectos_realizados").doc(idDocumentoId)
                refSalasupdate.update({pdfName3 : pdf.name , pdfUrl3 : pdfUrl})
            }
        })
        setTimeout(() => {
            setLoadingImgDetail(false)
            setModal2(false)
            setEditProjects(false)
        }, 7000);
    }

    const eliminarImagen = (item, tipo) => {
        if(tipo == 1){
        let idItem = id.toString()
        let desertRef = storage.ref(`${idItem}/imgProyectoDetalle/${item}`)
        desertRef.delete().then(() => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Imagen eliminada correctamente',
                showConfirmButton: false,
                timer: 2500
            })
            let imagenes = []
            let idItem = id.toString()
            storage.ref(`${idItem}/imgProyectoDetalle`).listAll()
                .then(async response => {
                    for (const iterator of response["items"]) {
                        const url = await iterator.getDownloadURL();
                        const nombre = iterator.name
                        imagenes.push({ img: url, name: nombre })
                    }
                }).catch(error => {
                    console.log(error);
                })
            setImgProyectSelect(imagenes)
            setModal1(false)
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al eliminar imagen, por favor intenta de nuevo!',
            })
        });
    }else{
        let idItem = id.toString()
        let desertRef = storage.ref(`${idItem}/imgProyectoDiseños/${item}`)
        desertRef.delete().then(() => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Imagen eliminada correctamente',
                showConfirmButton: false,
                timer: 2500
            })
            let imagenes = []
            let idItem = id.toString()
            storage.ref(`${idItem}/imgProyectoDiseños`).listAll()
                .then(async response => {
                    for (const iterator of response["items"]) {
                        const url = await iterator.getDownloadURL();
                        const nombre = iterator.name
                        imagenes.push({ img: url, name: nombre })
                    }
                }).catch(error => {
                    console.log(error);
                })
            setImgProyectSelect(imagenes)
            setModal1(false)
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al eliminar imagen, por favor intenta de nuevo!',
            })
        });
    }
    }

    const cancelUpdate = () => {
        setModal1(false)
        setModal2(false)
        setModal(false)
        setActiveForm(false)
    }

    const insertImgDetalle = async (type) => {
        if(type == 1){
        let img = addImagenDetail.target.files[0]
        let idItem = id.toString()
        try {
            const imagenRef = await storage.ref().child(idItem).child("imgProyectoDetalle").child(img.name)
            await imagenRef.put(img)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Imagen creada correctamente',
                showConfirmButton: false,
                timer: 2500
            })
            setModal1(false)
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al insertar imagen, por favor intenta de nuevo!',
            })
        }
    }else{
        let img = addImagenDiseños.target.files[0]
        let idItem = id.toString()
        try {
            const imagenRef = await storage.ref().child(idItem).child("imgProyectoDiseños").child(img.name)
            await imagenRef.put(img)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Imagen creada correctamente',
                showConfirmButton: false,
                timer: 2500
            })
            setModal3(false)
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al insertar imagen, por favor intenta de nuevo!',
            })
        }
    }
    }

    useEffect(() => {
        cleanItems()
        getNewProjects()
        getProjectsVendidos()
        setAdmin(localStorage.getItem("active"))
    }, [])



    return (
        <div>
            {/* si esta activado, aparece ventana de formulario para registro o edicion de proyectos */}
            {activeSpiner ? (
                <div className='spinner'>
                    <MutatingDots height='100' width='100' color='green' ariaLabel='Creando Proyecto...' />
                </div>
            ) : (
                <>
                    {activeForm == false ? (
                        <>
                            <div>
                                <FirstCarrousel />
                            </div>
                            <nav className="navbar navbar-light bg-light">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="#">Nuevos Proyectos</a>
                                </div>
                                {admin ? (
                                    <Button variant="contained" onClick={() => prepareNewProjects()}> Nuevo proyecto </Button>
                                ) : null}
                            </nav>

                            <div className='body-content'>
                                {proyectos.map((item, i) => (
                                    <div className='card-new' key={i}>
                                        {admin ? (
                                            <div className='card-boton'>
                                                <IconButton aria-label="delete" color='error' onClick={() => deleteProyects(item)}><DeleteIcon sx={{ fontSize: 30 }} /></IconButton>
                                                <IconButton color="primary" onClick={() => prepareEditProject(item)}><EditIcon /></IconButton>
                                                <IconButton color="success" onClick={() => proyectoVendido(item)}><MonetizationOnIcon /></IconButton>
                                            </div>
                                        ) : null}
                                        <div className='face front'>
                                            <div>
                                                <img
                                                    src={item.data.urlImagen}
                                                    alt="First slide"
                                                    onClick={() => seleccionproyect(item)}
                                                />
                                            </div>
                                            <h3>{item.data.titulo}</h3>
                                        </div>
                                        <div className='face back' onClick={() => seleccionproyect(item)}>
                                            <h3>{item.data.titulo}</h3>
                                            <p>{item.data.descripcion}</p>
                                            <div className='link'>
                                                <p>{item.data.ubicacion}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <nav className="navbar navbar-light bg-light">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="#">Proyectos Vendidos</a>
                                </div>
                            </nav>

                            <div className='body-content'>
                                {proyectosVendidos.map((item, i) => (
                                    <div className='card-new' key={i}>
                                        {admin ? (
                                            <div className='card-boton'>
                                                <IconButton aria-label="delete" color='error' onClick={() => deleteProyects(item)}><DeleteIcon sx={{ fontSize: 30 }} /></IconButton>
                                                <IconButton color="primary" onClick={() => prepareEditProject(item)}><EditIcon /></IconButton>
                                            </div>
                                        ) : null}
                                        <div className='face front'>
                                            <div>
                                                <img
                                                    src={item.data.urlImagen}
                                                    alt="First slide"
                                                    onClick={() => seleccionproyect(item)}
                                                />
                                            </div>
                                            <h3>{item.data.titulo}</h3>
                                        </div>
                                        <div className='face back' onClick={() => seleccionproyect(item)}>
                                            <h3>{item.data.titulo}</h3>
                                            <p>{item.data.descripcion}</p>
                                            <div className='link'>
                                                <p>{item.data.ubicacion}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>

                    ) :
                        // segmento para el modo creacion o edicion de proyectos
                        (
                            <>
                                <h2 id="child-modal-title">{editProjects ? "Editar Proyecto" : "Nuevo Proyecto"}</h2>
                                <form onSubmit={editProjects == false ? newProyects : updateProyect}>
                                    {error && (
                                        <div className="alert-danger" style={{ marginBotton: "10px" }}>{error}</div>
                                    )}
                                    <br />
                                    <div style={{ display: "flex" }}>
                                        <input
                                            style={{ marginRight: "1%" }}
                                            type="text"
                                            className='form-control'
                                            placeholder="Nombre del proyecto"
                                            onChange={e => setAddTitulo(e.target.value)}
                                            value={addTitulo}
                                        />
                                        <input
                                            style={{ marginRight: "1%" }}
                                            type="text"
                                            className='form-control'
                                            placeholder="Dirección del proyecto"
                                            onChange={e => setAddUbicacion(e.target.value)}
                                            value={addUbicacion}
                                        />
                                        <input
                                            style={{ width: "70%" }}
                                            type="text"
                                            className='form-control'
                                            placeholder="Latitud y Longitud del Proyecto"
                                            onChange={e => setAddLocalizacion(e.target.value)}
                                            value={addLocalizacion}
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <MultiSelect
                                            className='itemsMultiselect'
                                            options={options}
                                            value={ItemSelected}
                                            onChange={setItemSelected}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <br />
                                    <textarea
                                        type="text"
                                        className='form-control'
                                        placeholder="Descripción breve del proyecto"
                                        maxLength={350}
                                        onChange={e => setAddDescripcion(e.target.value)}
                                        value={addDescripcion}
                                    />
                                    <br />
                                    <textarea
                                        type="text"
                                        className='form-control'
                                        placeholder="descripción completa del proyecto"
                                        maxLength={7000}
                                        onChange={e => setAddDescripcionCompleta(e.target.value)}
                                        value={addDescripcionCompleta}
                                    />
                                    <br />
                                    <textarea
                                        type="text"
                                        className='form-control'
                                        placeholder="descripción completa del diseño"
                                        maxLength={7000}
                                        onChange={e => setAddDescripcion1(e.target.value)}
                                        value={addDescripcion1}
                                    />
                                    <br />
                                    {editProjects == true ? (
                                        <>
                                            <button type="button" onClick={() => openEditImagen(1)} style={{ background: "#2E7D32", borderRadius: "10px", color: "white", marginRight: "20px", border: "none" }}>Imagen Principal</button>
                                            <button type="button" onClick={() => openEditImagen(2)} style={{ background: "#2E7D32", borderRadius: "10px", color: "white", marginRight: "20px", border: "none" }}>Imagen detalle</button>
                                            <button type="button" onClick={() => openEditImagen(3)} style={{ background: "#2E7D32", borderRadius: "10px", color: "white", border: "none" }}>Imagen diseños</button>
                                            <br />
                                            <br />
                                            <button type="button" onClick={() => openEditPdfs()} style={{ background: "#2E7D32", borderRadius: "10px", color: "white", border: "none" }}>Documentos</button>
                                            <br />
                                        </>
                                    ) : null}

                                    {editProjects == false ? (
                                        <div className='custom-file'>
                                            <label for="file" style={{ marginRight: "20px", color: "blue" }}><strong>Imagen Principal</strong></label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id='inputGroupFile01'
                                                onChange={e => setAddImagen(e)}
                                            />
                                            <br />
                                            <br />

                                            <label for="file" style={{ marginRight: "20px", color: "blue", marginBottom: "20px" }}><strong>Pdf Informacion proyecto</strong></label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id='inputGroupFile01'
                                                onChange={e => setAddPdf(e)}
                                            />
                                            <br />
                                            <label for="file" style={{ marginRight: "20px", color: "blue", marginBottom: "20px" }}><strong>Pdf Informacion proyecto</strong></label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id='inputGroupFile01'
                                                onChange={e => setAddPdf1(e)}
                                            />
                                            <br />
                                            <label for="file" style={{ marginRight: "20px", color: "blue", marginBottom: "20px" }}><strong>Pdf Informacion proyecto</strong></label>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id='inputGroupFile01'
                                                onChange={e => setAddPdf2(e)}
                                            />
                                            <br />
                                            <label for="file" style={{ marginRight: "20px", color: "blue" }}><strong>Imagen Detalle</strong></label>
                                            <input
                                                type="file"
                                                multiple
                                                className="custom-file-input"
                                                id='inputGroupFile02'
                                                onChange={e => setAddImagenDetalle(e)}
                                            />
                                            <br />
                                            <label for="file" style={{ marginRight: "20px", color: "blue" }}><strong>Imagen Diseños</strong></label>
                                            <input
                                                type="file"
                                                multiple
                                                className="custom-file-input"
                                                id='inputGroupFile03'
                                                onChange={e => setAddImagenDiseños(e)}
                                            />


                                        </div>
                                    ) : null}
                                    <br />
                                    <div style={{ marginBotton: "1%" }}>
                                        <Button variant="contained" disableElevation type='submit' style={{ marginRight: "1%" }}>{editProjects == false ? "Agregar" : "Actualizar"}</Button>
                                        <Button variant="outlined" color="error" onClick={() => cancelUpdate()} >Cancelar</Button>
                                    </div>
                                    <br />
                                </form>
                            </>
                        )}
                </>
            )
            }
            <Modal
                hideBackdrop
                open={modal1}
                onClose={() => setModal1(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <h2 id="child-modal-title"> Imagenes detalle</h2>
                    <br></br>
                    <div className="list-images">
                        {loadingImgDetail ? (
                            <div className="images">
                                <div>
                                    <MutatingDots height='100' width='100' color='green' ariaLabel='Cargando Imagenes...' />
                                </div>
                            </div>
                        ) : (
                            <div className="images">
                                {imgProyectSelect.map((item) => (
                                    <div className='modal-edit-imagen'>
                                        <img src={item.img} loading="lazy" />
                                        <DeleteIcon style={{ color: "#D32F2F" }} className="icon-modal" onClick={() => eliminarImagen(item.name, 1)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <br></br>
                    {addImagenDetail ? (
                        <div style={{ display: "flex" }}>
                            <p>{addImagenDetail.target.files[0].name}  <FiUserCheck size={18} style={{ color: "green" }} /></p>
                        </div>
                    ) : (
                        <>
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={e => setAddImagenDetail(e)}
                                />
                                <FiUploadCloud size={80} style={{ cursor: "pointer", color: "green", marginLeft: "250%" }} />
                            </label>
                        </>
                    )}
                    <br></br>
                    <br></br>
                    <div>
                        <Button type="button" onClick={() => insertImgDetalle(1)}>Subir Imagen</Button>
                        <Button variant="outlined" color="error" onClick={() => (setModal1(false), setAddImagenDetail(""))} style={{ float: "right" }}>Cancelar</Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                hideBackdrop
                open={modal3}
                onClose={() => setModal3(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <h2 id="child-modal-title"> Imagenes diseños</h2>
                    <br></br>
                    <div className="list-images">
                        {loadingImgDetail ? (
                            <div className="images">
                                <div>
                                    <MutatingDots height='100' width='100' color='green' ariaLabel='Cargando Imagenes...' />
                                </div>
                            </div>
                        ) : (
                            <div className="images">
                                {imgProyectDiseñosSelect.map((item) => (
                                    <div className='modal-edit-imagen'>
                                        <img src={item.img} loading="lazy" />
                                        <DeleteIcon style={{ color: "#D32F2F" }} className="icon-modal" onClick={() => eliminarImagen(item.name, 2)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <br></br>
                    {addImagenDiseños ? (
                        <div style={{ display: "flex" }}>
                            <p>{addImagenDiseños.target.files[0].name}  <FiUserCheck size={18} style={{ color: "green" }} /></p>
                        </div>
                    ) : (
                        <>
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={e => setAddImagenDiseños(e)}
                                />
                                <FiUploadCloud size={80} style={{ cursor: "pointer", color: "green", marginLeft: "250%" }} />
                            </label>
                        </>
                    )}
                    <br></br>
                    <br></br>
                    <div>
                        <Button type="button" onClick={() => insertImgDetalle(2)}>Subir Imagen</Button>
                        <Button variant="outlined" color="error" onClick={() => (setModal3(false), setAddImagenDiseños(""))} style={{ float: "right" }}>Cancelar</Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                hideBackdrop
                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <h2 id="child-modal-title"> Imagenes Principales</h2>
                    <br></br>
                    <div className="list-images">
                        {activeSpiner2 == true ? (
                            <div className="images">
                                <div>
                                    <MutatingDots height='100' width='100' color='green' ariaLabel='Cargando Imagenes...' />
                                </div>
                            </div>
                        ) : (
                            <div className="images">
                                <div className='modal-edit-imagen'>
                                    {imgUpload == true ? (
                                        <div style={{ marginLeft: "50%", marginTop: "80%" }}>
                                            <BsFolderCheck size={50} style={{ color: "green" }} />
                                        </div>
                                    ) : (
                                        <label>
                                            <input
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={e => updateImagen(e)}
                                            />
                                            <img src={editImagen} style={{ cursor: "pointer" }} />
                                        </label>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div>
                        <Button variant="outlined" color="error" onClick={() => (setModal(false), setAddImagenDetail(""))} style={{ float: "right" }}>Cancelar</Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                hideBackdrop
                open={modal2}
                onClose={() => setModal2(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <h2 id="child-modal-title"> Documentos detalle</h2>
                    <br></br>
                    <div className="list-images">
                        {loadingImgDetail ? (
                            <div className="images">
                                <div>
                                    <MutatingDots height='100' width='100' color='green' ariaLabel='Cargando Imagenes...' />
                                </div>
                            </div>
                        ) : (
                            <div >
                                {pdfsProyectSelect.map((item) => (
                                    <div >
                                        <label>
                                            <input
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={e => updatePdf(e, item)}
                                            />
                                            <BsFillFilePdfFill size={60} style={{ cursor: "pointer", color: "#AA0A00" }} />{item.name}
                                        </label>
                                        <br></br>
                                        <br></br>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <br></br>
                    {pdfsProyectSelect.length < 3 ? (
                        <>
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={e => añadirPdf(e, pdfsProyectSelect)}
                                />
                                <FiUploadCloud size={60} style={{ cursor: "pointer", color: "green", marginLeft: "400%" }} />
                            </label>
                            <br></br>
                            <br></br>
                        </>
                    ) : null}
                    <div>
                        <Button variant="outlined" color="error" onClick={() => (setModal2(false), setAddImagenDetail(""))} style={{ float: "right" }}>Cancelar</Button>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

export default Proyects


