import { React, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import { MutatingDots } from 'react-loader-spinner'
import emailjs from 'emailjs-com'


const Contacts = (props) => {

    const [nombre, setNombre] = useState("")
    const [telefono, setTelefono] = useState("")
    const [email, setEmail] = useState("")
    const [mensaje, setMensaje] = useState("")
    const [activeSpiner, setActiveSpiner] = useState(false)
    const [error, setError] = useState(null)


    const procesarDatos = (e) => {
        e.preventDefault()
        let error = false
        //validadores de campos vacios 
        if (!nombre.trim()) {
            setError("el nombre no debe estar vacio")
            error = true
        }
        if (!telefono.trim()) {
            setError("el telefono no debe estar vacio")
            error = true
        }
        if (!email.trim()) {
            setError("el email no debe estar vacio")
            error = true
        }
        if (!mensaje.trim()) {
            setError("el mensaje no debe estar vacio")
            error = true
        }
        if (validarEmail(email) == false) {
            setError("El e-mail no es valido")
            error = true
        }
        if (error == false) {
            setError("")
            enviarMensaje()
        }
    }

    const validarEmail = (valor) => {
        let re = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
        if (!re.exec(valor)) {
            setError("El correo electronico introducido no es valido")
            return false
        } else {
            return true
        }
    }

    const enviarMensaje = () => {
        console.log("entra");
        let template = {
            name: "Tu Hogar Campestre",
            from_name: nombre,
            correo: email,
            copia: "jhonprogramacion@gmail.com",
            contacto: telefono,
            message: mensaje
        }
        emailjs.send('service_zx2mxsd', 'template_qh5bsjh', template, 'user_TryOdBRUvXpWE2kE1tZ7c')
            .then((response) => {
                setActiveSpiner(true)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Mensaje enviado correctamente',
                    showConfirmButton: false,
                    timer: 2000
                })
                setTimeout(() => {
                    props.history.push('/')
                }, 2500);
            }, (err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocurrio un error al enviar el correo por favor intenta de nuevo!',
                })
            });


    }

    return (
        <div className='conten-coment'>
            <div className="mt-5">
                {activeSpiner == true ? (
                    <div className='alert'>
                        <div className="spinner">
                            <MutatingDots
                                heigth="130"
                                width="130"
                                color='grey'
                                ariaLabel='loading'
                                visible={activeSpiner}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <h3 className="text-center" style={{ color: "gray" }}> CONTACTO</h3>
                        <br />
                        <div className="row justify-content-center" style={{ width: "100%", heigth: "100%" }}>
                            <div className="col-12 col-sm-8 col-md-6 col-xl-4">
                                <form onSubmit={procesarDatos}>
                                    {/* al escribir {error && ()} le estamos diciendo si error es diferente de nulo */}
                                    {error && (
                                        <div className="alert alert-danger">{error}</div>
                                    )}
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Nombre"
                                        onChange={e => setNombre(e.target.value)}
                                        value={nombre}
                                    />
                                    <input
                                        type="number"
                                        className="form-control mb-1"
                                        placeholder="Telefono de contacto"
                                        onChange={e => setTelefono(e.target.value)}
                                        value={telefono}
                                    />

                                    <input
                                        type="email"
                                        className="form-control mb-2"
                                        placeholder="E-mail"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                    />

                                    <textarea
                                        className="form-control mb-2"
                                        placeholder="Mensaje"
                                        rows="5"
                                        onChange={e => setMensaje(e.target.value)}
                                        value={mensaje}
                                    />
                                    <div className='botonForm'>
                                        <button className="btn btn-outline-primary btn-lg " type="submit" style={{ width: "120px" }}>
                                            Enviar
                                        </button>
                                    </div>
                                    <br />
                                </form>
                            </div>
                        </div>
                    </>
                )}


            </div>
        </div>

    )
}

export default withRouter(Contacts)
