import React, { useState } from 'react';
import turco from '../assets/iconos/bañoTurco.png'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer
  } from "react-google-maps";

  let dataMap = [
    {
      id: 1,
      fecha : 'Visita 1',
      latitud : 4.565047573425235,
      longitud : -75.65024684718102
 
    },
    {
      id: 2,
      fecha : 'Visita 2',
      latitud : 4.535761251467456,
      longitud : -75.67662684986156
    },
    {
      id: 3,
      fecha : 'Visita 3',
      latitud : 4.533781887041522,
      longitud : -75.70147371930665
    }
  ]

  const Maps = withScriptjs(withGoogleMap(props => 
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.posicion}
      // defaultCenter={{lat: 4.565047573425235, lng: -75.65024684718102}}
    >
      {/* {dataMap.map(item => (
        <Marker 
          key={item.id}
          position={{
            lat : item.latitud,
            lng : item.longitud
          }}
          label={item.fecha}
          icon={turco}
        
          
        />
      ))} */}
      <Marker
        position={props.posicion}
      />
    </GoogleMap>
  ));

  export default Maps

