import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyBDspjxDUGHNJeRr4UXVJPsF8OLB6aFVNM",
  authDomain: "djm-construcciones.firebaseapp.com",
  projectId: "djm-construcciones",
  storageBucket: "djm-construcciones.appspot.com",
  messagingSenderId: "32440736474",
  appId: "1:32440736474:web:ffbff38b33291fc07323e3",
  measurementId: "G-993LM9G6TQ"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

export {db, auth, firebase, storage}
