import { Image } from 'antd';
import React from 'react';
import { Autoplay, EffectCoverflow, Keyboard, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/free-mode";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Cards from './Cards';

const CarrouselSecondDetail = (props) => {
  return (
    <div className='description-proyects'>
      <div className="banner_container_detail_swipper">
        <div className='container-swipper_one'>
        <Cards>
          <div className="card-body">
            <h2>{props.infoProyect.titulo}</h2>
            {props.infoProyect.descripcion1 !== "false" ? (
              <p className="card-text ">{props.infoProyect.descripcion1}</p>
            ) : null}
            {props.infoProyect.pdfName1 != "no" ? (
              <div>
                <a href={props.infoProyect.pdfUrl1} target="_blank">{props.infoProyect.pdfName1}</a>
                <br />
              </div>
            ) : null}

            {props.infoProyect.pdfName2 != "no" ? (
              <div>
                <a href={props.infoProyect.pdfUrl2} target="_blank">{props.infoProyect.pdfName2}</a>
                <br />
              </div>
            ) : null}

            {props.infoProyect.pdfName3 != "no" ? (
              <div>
                <a href={props.infoProyect.pdfUrl3} target="_blank">{props.infoProyect.pdfName3}</a>
                <br />
              </div>
            ) : null}
            <br /><small className="text-muted">{props.infoProyect.ubicacion}</small>
          </div>
        </Cards>
      </div>
      <div className='container-swipper_two'>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          navigation={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 60,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false
          }}
          keyboard={{
            enabled: true
          }}
          pagination={{
            clickable: true
          }}
          modules={[Keyboard, EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          {props.data.map((media) => (
            <>
              <SwiperSlide>
                <Image
                  className="img-modal"
                  src={media.img}
               
                />
                <div className="text-carousel-detail">
                  <p>{media.name}</p>
                </div>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </div>
      </div>
     
      </div>
  )
}

export default CarrouselSecondDetail