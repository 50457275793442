import React, { useState } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { db, firebase } from '../Firebase';
import Maps from '../util/Maps';
import Cards from './Cards';
//iconos para items 
import { BiLandscape } from "react-icons/bi";
import { CgGym } from "react-icons/cg";
import { FaCarSide, FaSwimmingPool, FaTrashRestore, FaWheelchair, FaWifi } from "react-icons/fa";
import { GiCctvCamera, GiElectricalResistance, GiElevator, GiFloorPolisher, GiFlowerPot, GiForestCamp, GiSoccerKick, GiStonePath } from "react-icons/gi";
import { GrSolaris, GrUserPolice, GrWorkshop } from "react-icons/gr";
import { HiOutlineCash } from "react-icons/hi";
import { MdBalcony, MdBathroom, MdComputer, MdEmojiPeople, MdMeetingRoom, MdOutlineSmartToy, MdPeopleAlt, MdPets } from "react-icons/md";
import { SiPicnic } from "react-icons/si";
import bañoTurco from '../assets/iconos/bañoTurco.png';
import bbq from '../assets/iconos/bbq.png';
import jacuzzi from '../assets/iconos/jacuzzi.png';
import sauna from '../assets/iconos/sauna.png';
import zonaSocial from '../assets/iconos/zonaSocial.png';
import CarrouselFirstDetail from './CarrouselFirstDetail';
import CarrouselSecondDetail from './CarrouselSecondDetail';
import ModalDetail from '../subcomponentes/ModalDetailVideo';


const DetailProyect = (props) => {
    const [imgProyectSelect, setImgProyectSelect] = useState([])
    const [imgDiseñoProyectSelect, setImgDiseñoProyectSelect] = useState([])
    const [proyect, setProyect] = useState()
    const [dataProyect, setDataProyect] = useState([])
    const [activeSpiner, setActiveSpiner] = useState(false)
    //iconos
    const [iCgGym, setICgGym] = useState(false)
    const [iMdOutlineSmartToy, setIMdOutlineSmartToy] = useState(false)
    const [iGrUserPolice, setIGrUserPolice] = useState(false)
    const [iGiFloorPolisher, setIGiFloorPolisher] = useState(false)
    const [iFaSwimmingPool, setIFaSwimmingPool] = useState(false)
    const [iGrSolaris, setIGrSolaris] = useState(false)
    const [ijacuzzi, setIjacuzzi] = useState(false)
    const [isauna, setIsauna] = useState(false)
    const [ibañoTurco, setIbañoTurco] = useState(false)
    const [izonaSocial, setIzonaSocial] = useState(false)
    const [ibbq, setIbbq] = useState(false)
    const [iGiFlowerPot, setIGiFlowerPot] = useState(false)
    const [iGiElevator, setIGiElevator] = useState(false)
    const [iFaWheelchair, setIFaWheelchair] = useState(false)
    const [iGiSoccerKick, setIGiSoccerKick] = useState(false)
    const [iGrWorkshop, setIGrWorkshop] = useState(false)
    const [iBiLandscape, setIBiLandscape] = useState(false)
    const [iMdBalcony, setIMdBalcony] = useState(false)
    const [iMdPeopleAlt, setIMdPeopleAlt] = useState(false)
    const [iMdEmojiPeople, setIMdEmojiPeople] = useState(false)
    const [iGiStonePath, setIGiStonePath] = useState(false)
    const [iMdPets, setIMdPets] = useState(false)
    const [iGiElectricalResistance, setIGiElectricalResistance] = useState(false)
    const [iFaTrashRestore, setIFaTrashRestore] = useState(false)
    const [iSiPicnic, setISiPicnic] = useState(false)
    const [iGiForestCamp, setIGiForestCamp] = useState(false)
    const [iHiOutlineCash, setIHiOutlineCash] = useState(false)
    const [iHiOutlineCashnoVis, setIHiOutlineCashnoVis] = useState(false)
    const [iFaWifi, setIFaWifi] = useState(false)
    const [iGiCctvCamera, setIGiCctvCamera] = useState(false)
    const [iMdBathroom, setIMdBathroom] = useState(false)
    const [iMdMeetingRoom, setIMdMeetingRoom] = useState(false)
    const [iMdComputer, setIMdComputer] = useState(false)
    const [iFaCarSide, setIFaCarSide] = useState(false)
    const [textFormateado, setTextFormateado] = useState("")
    const [modalDetail, setModalDetail] = useState(true)
    const prueba = () => {
        document.getElementById("textFormato").innerHTML = textFormateado
    }

    const getProject = async (idproyecto) => {
        var dataProyect = await db.collection("proyectos_realizados").doc(idproyecto)
        dataProyect.get().then((doc) => {
            let res = doc.data()
            console.log(res);
            setDataProyect(res)


            let itemProyecto = res.items
            itemProyecto.map((data) => {
                switch (data.value) {
                    case "12": setICgGym(true)
                        break;
                    case "15": setIMdOutlineSmartToy(true)
                        break;
                    case "29": setIGrUserPolice(true)
                        break;
                    case "22": setIGiFloorPolisher(true)
                        break;
                    case "20": setIFaSwimmingPool(true)
                        break;
                    case "26": setIGrSolaris(true)
                        break;
                    case "14": setIjacuzzi(true)
                        break;
                    case "23": setIsauna(true)
                        break;
                    case "5": setIbañoTurco(true)
                        break;
                    case "34": setIzonaSocial(true)
                        break;
                    case "2": setIbbq(true)
                        break;
                    case "33": setIGiFlowerPot(true)
                        break;
                    case "3": setIGiElevator(true)
                        break;
                    case "1": setIFaWheelchair(true)
                        break;
                    case "8": setIGiSoccerKick(true)
                        break;
                    case "10": setIGrWorkshop(true)
                        break;
                    case "7": setIBiLandscape(true)
                        break;
                    case "6": setIMdBalcony(true)
                        break;
                    case "17": setIMdPeopleAlt(true)
                        break;
                    case "28": setIMdEmojiPeople(true)
                        break;
                    case "24": setIGiStonePath(true)
                        break;
                    case "16": setIMdPets(true)
                        break;
                    case "21": setIGiElectricalResistance(true)
                        break;
                    case "25": setIFaTrashRestore(true)
                        break;
                    case "27": setISiPicnic(true)
                        break;
                    case "18": setIGiForestCamp(true)
                        break;
                    case "30": setIHiOutlineCash(true)
                        break;
                    case "32": setIFaWifi(true)
                        break;
                    case "9": setIGiCctvCamera(true)
                        break;
                    case "4": setIMdBathroom(true)
                        break;
                    case "13": setIMdMeetingRoom(true)
                        break;
                    case "11": setIMdComputer(true)
                        break;
                    case "19": setIFaCarSide(true)
                        break;
                    case "31": setIHiOutlineCashnoVis(true)
                        break;
                }
            })
        })
    }

    const imageProyect = async (doc) => {
        const storage = firebase.storage();
        let imagenes = []
        let imagenesDetalle = []
        storage.ref(`${doc}/imgProyectoDetalle`).listAll()
            .then(async response => {
                for (const iterator of response["items"]) {
                    const url = await iterator.getDownloadURL();
                    const nombre = iterator.name
                    let indice = nombre.indexOf(".");
                    const nombreFinal = nombre.substring(0, indice)
                    imagenes.push({ img: url, name: nombreFinal })
                }
            }).catch(error => {
                console.log(error);
            })

        storage.ref(`${doc}/imgProyectoDiseños`).listAll()
            .then(async response => {
                for (const iterator of response["items"]) {
                    const url = await iterator.getDownloadURL();
                    const nombre = iterator.name
                    let indice = nombre.indexOf(".");
                    const nombreFinal = nombre.substring(0, indice)
                    imagenesDetalle.push({ img: url, name: nombreFinal })
                }
            }).catch(error => {
                console.log(error);
            })
        setImgDiseñoProyectSelect(imagenesDetalle)
        console.log(imagenes);
        setImgProyectSelect(imagenes)
        setTimeout(() => {
            setActiveSpiner(false)
        }, 6000);
    }

    useState(() => {
        setActiveSpiner(true)
        let URLactual = (new URL(document.location)).searchParams;
        let idproyecto = URLactual.get('id');
        let docDocument = URLactual.get('doc')
        imageProyect(docDocument)
        getProject(idproyecto)

    }, [])

    return (
        <div>
            {activeSpiner ? (
                <div className='spinner'>
                    <MutatingDots height='100' width='100' color='green' ariaLabel='Creando Proyecto...' />
                </div>
            ) : (
                <div>
                  {/* <ModalDetail modalVideo={modalDetail} setModalVideo={setModalDetail}/> */}
                    {/* primer carrusel y detalle */}
                    <CarrouselFirstDetail data={imgProyectSelect} infoProyect={dataProyect} />
                    
                    <div className="card-items-detail-h" >
                        <Cards>
                            <div className='item-card-h'>
                                {iCgGym ? (
                                    <CgGym className='item-configure' title="Gimnasio " />
                                ) : null}
                                {iMdOutlineSmartToy ? (
                                    <MdOutlineSmartToy className='item-configure' title="Juegos infantiles " />
                                ) : null}
                                {iGrUserPolice ? (
                                    <GrUserPolice className='item-configure' title="Vigilancia y seguridad " />
                                ) : null}
                                {iGiFloorPolisher ? (
                                    <GiFloorPolisher className='item-configure' title="Portería  " />
                                ) : null}
                                {iFaSwimmingPool ? (
                                    <FaSwimmingPool className='item-configure' title="Piscina " />
                                ) : null}
                                {iGrSolaris ? (
                                    <GrSolaris className='item-configure' title="Solarium " />
                                ) : null}
                                {ijacuzzi ? (
                                    <img src={jacuzzi} className='item-configure' title="Jacuzzi " />
                                ) : null}
                                {isauna ? (
                                    <img src={sauna} className='item-configure' title="Sauna " />
                                ) : null}
                                {ibañoTurco ? (
                                    <img src={bañoTurco} className='item-configure' title="Baño turco " />
                                ) : null}
                                {izonaSocial ? (
                                    <img src={zonaSocial} className='item-configure' title="Zona social " />
                                ) : null}
                                {ibbq ? (
                                    <img src={bbq} className='item-configure' title="Área de BBQ " />
                                ) : null}
                                {iGiFlowerPot ? (
                                    <GiFlowerPot className='item-configure' title="Zonas verdes/Jardín " />
                                ) : null}
                                {iGiElevator ? (
                                    <GiElevator className='item-configure' title="Ascensor " />
                                ) : null}
                                {iFaWheelchair ? (
                                    <FaWheelchair className='item-configure' title="Accesibilidad/Apto para personas con movilidad reducid " />
                                ) : null}
                                {iGiSoccerKick ? (
                                    <GiSoccerKick className='item-configure' title="Cancha múltiple/Espacios deportivos " />
                                ) : null}
                                {iGrWorkshop ? (
                                    <GrWorkshop className='item-configure' title="Coworking/Salón de trabajo " />
                                ) : null}
                                {iBiLandscape ? (
                                    <BiLandscape className='item-configure' title="Buena vista/Paisaje " />
                                ) : null}
                                {iMdBalcony ? (
                                    <MdBalcony className='item-configure' title="Balcón " />
                                ) : null}
                                {iMdPeopleAlt ? (
                                    <MdPeopleAlt className='item-configure' title="Multifamiliar " />
                                ) : null}
                                {iMdEmojiPeople ? (
                                    <MdEmojiPeople className='item-configure' title="Unifamiliar " />
                                ) : null}
                                {iGiStonePath ? (
                                    <GiStonePath className='item-configure' title="Senderos " />
                                ) : null}
                                {iMdPets ? (
                                    <MdPets className='item-configure' title="Mascota/Petfriendly " />
                                ) : null}
                                {iGiElectricalResistance ? (
                                    <GiElectricalResistance className='item-configure' title="Planta eléctrica " />
                                ) : null}
                                {iFaTrashRestore ? (
                                    <FaTrashRestore className='item-configure' title=" Shut de basuras" />
                                ) : null}
                                {iSiPicnic ? (
                                    <SiPicnic className='item-configure' title="Terraza recreativa/Zona de picnic " />
                                ) : null}
                                {iGiForestCamp ? (
                                    <GiForestCamp className='item-configure' title=" Naturaleza/Campestre" />
                                ) : null}
                                {iHiOutlineCash ? (
                                    <HiOutlineCash className='item-configure' title=" VIS" />
                                ) : null}
                                {iHiOutlineCashnoVis ? (
                                    <HiOutlineCash className='item-configure' title=" NO VIS" />
                                ) : null}
                                {iFaWifi ? (
                                    <FaWifi className='item-configure' title="Wifi " />
                                ) : null}
                                {iGiCctvCamera ? (
                                    <GiCctvCamera className='item-configure' title="Circuito cerrado de TV " />
                                ) : null}
                                {iMdBathroom ? (
                                    <MdBathroom className='item-configure' title="Baños " />
                                ) : null}
                                {iMdMeetingRoom ? (
                                    <MdMeetingRoom className='item-configure' title="Habitaciones " />
                                ) : null}
                                {iMdComputer ? (
                                    <MdComputer className='item-configure' title="Estudio " />
                                ) : null}
                                {iFaCarSide ? (
                                    <FaCarSide className='item-configure' title="Parqueaderos " />
                                ) : null}
                            </div>
                        </Cards>
                    </div>

                    {/* segundo carrusel y detalle */}
                    <CarrouselSecondDetail data={imgDiseñoProyectSelect} infoProyect={dataProyect} />

                    {/* mapas */}
                    <br></br>
                    <div className='description-proyects'>
                        <Cards>
                            <div className="card card-map">
                                <Maps
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDyDQjJ6ZU4adkVlNChiLyaOpRRu_2qWh4&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    posicion={{ lat: dataProyect.latitud, lng: dataProyect.longitud }}
                                >
                                </Maps>
                            </div>
                        </Cards>
                    </div>
                </div>
            )}
        </div >
    )
}

export default DetailProyect