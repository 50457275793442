import React from 'react'
import banner from '../assets/bannerProyectos.jpg'
import banner2 from '../assets/bannerProyectos2.jpg'
import banner3 from '../assets/bannerProyectos3.jpg'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { EffectCoverflow, Pagination, Keyboard, FreeMode, Autoplay } from "swiper";

const FirstCarrousel = () => {

  const dataBanner = [{id:1, link: banner}, {id:2, link: banner2}, {id:3, link: banner3}]
  return (
    <div className="banner_container_swipper">
      
      <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 60,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true
                }}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false
                }}
                keyboard={{
                  enabled: true
                }}
                pagination={{
                  clickable: true
                }}
                modules={[Keyboard, EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper"
              >
                {dataBanner.map((media) => (
                      <SwiperSlide>
                        <img
                          key={media.id}
                          src={media.link}
                        />
                      </SwiperSlide>
                ))}
              </Swiper>
    </div>
  )
}

export default FirstCarrousel